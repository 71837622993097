import {
  Col,
  Container,
  Row,
  Form,
  FormControl,
  Button,
  Stack,
  Spinner,
} from 'react-bootstrap';
import Tree from './treejs/treejs';
import DiagnosisRunActionPanel from './DealerDiagnosis/components/diagnosisRunActionPanel';
import GrantExceptionPanel from './GrantException/components/grantExceptionPanel';
import ReactJson from 'react-json-view';
import React from 'react';
import { format } from 'date-fns';
import { useState, useEffect, useCallback } from 'react';
import { config } from './config';
import buildInfo from './buildInfo';
import DiagnosisDashboard from './DealerDiagnosis/components/diagnosisDashboard';
import ajax from './treejs/ajax';
import _ from 'lodash';
import './App.css';

function App() {
  const [userProfile, setUserProfile] = useState();
  const [vins, setVins] = useState([]);
  const [toolVin, setToolVin] = useState();
  const [toolType, setToolType] = useState(null);
  const [sellerId, setSellerId] = useState();
  const [numOfDays, setNumOfDays] = useState();
  const [responseData, setResponseData] = useState();
  const [loading, setLoading] = useState(false);
  const defaultReconciliationFormSelections = {
    UpsideDirect: false,
    SaleActive: false,
    InspectionComplete: false,
    ReadyForListing: false,
    PendingListing: false,
    ReadyForAuction: false,
    ErrorState: false,
  };
  const [reconciliationFormSelections, setReconciliationFormSelections] =
    useState(defaultReconciliationFormSelections);
  const [reconciliationData, setReconciliationData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [renderingDiagnosisDashboard, setRenderingDiagnosisDashboard] = useState(false);
  const [dealerDiagnosisResponse, setdealerDiagnosisResponse] = useState(null);
  const [dealer, setDealer] = useState(null);
  const buildDate = format(new Date(buildInfo.buildDate), 'yyy/MM/dd kk:mm');
  var isProduction = config.ENVIRONMENT.toLowerCase() == 'production';
  const environment = isProduction ? 'PROD' : 'NON-PROD';
  var isSubscribed = false;

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const targetVins = params.vins;
    if (targetVins) {
      setVins(targetVins);
    }
    const targetAction = params.actions;
    if (targetAction == "reconciliation") {
      runReconciliation(false);
    }
  }, [setVins]);

  // Fetch user profile on load to determine app roles.
  useEffect(() => {
    getUserProfile();
  }, []);

  const isViewer = useCallback(
    () => Boolean(userProfile?.roles?.find((r) => r === 'Viewer' || r === 'Editor')),
    [userProfile]
  );
  const isEditor = useCallback(
    () => Boolean(userProfile?.roles?.find((r) => r === 'Editor')),
    [userProfile]
  );

  const getUserProfile = useCallback(async () => {
    setLoading(true);
    const url = '/api/user-profiles/mine';

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (!response.ok) {
        setErrorMessage('Must be signed into VAuto');
        setLoading(false);
        return;
      }

      const userProfile = await response.json();
      setUserProfile(userProfile);

      if (!userProfile.roles?.length) {
        setErrorMessage('You are not authorized to use this tool');
      }
      setLoading(false);
    } catch {
      setErrorMessage('Must be signed into VAuto');
      setLoading(false);
    }
  }, [setLoading, setErrorMessage, setUserProfile]);

  const handleRunDiagnosisActionSubmit = useCallback((event, dealer) => {
    setErrorMessage();
    setResponseData(null);
    setReconciliationData(null);
    setVins([]);
    setToolType();
    setToolVin(null);
    setLoading(true);
    setRenderingDiagnosisDashboard(false);

    if (dealer) {
      const _dealer = dealer.trim();
      const url = `/api/diagnostic/dealer-detail?dealer=${_dealer}`;
      setDealer(_dealer);

      ajax({
        url,
        success: (resp) => {
          setdealerDiagnosisResponse(resp);
          setRenderingDiagnosisDashboard(true);
          setLoading(false);
        },
        failed: (resp) => {
          if (resp != 200) {
            console.error("Getting error while fetching dealer details with status code", resp);
            setLoading(false);
          }
        }
      });
    } else {
      setLoading(false);
    }
    event.preventDefault();
  }, []);

  function setVinsQueryString(vins) {
    const params = new URLSearchParams(window.location.search);
    params.set('vins', vins);
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${params.toString()}${window.location.hash}`
    );
  }

  async function getData(vinArray) {
    let url = '/api/logs';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(vinArray),
    });

    if (!response.ok) {
      setErrorMessage('Must be signed into VAuto');
    }

    return response.json();
  }

  async function processNode(node) {
    if (node.data == null) {
      if (node.type === 'Url') {
        window.open(node.url, '_blank');
      } else if (node.type === 'Tool') {
        setToolVin(node.vin);
        setToolType(node.toolType);
      } else if (node.pathType === 'Relative') {
        var response = await fetch('/' + node.path, {
          method: 'GET',
          headers: {
            accept: '*/*',
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
        node.data = await response.json();
      } else if (node.pathType === 'Absolute') {
        var response = await fetch(node.path, {
          method: 'GET',
          headers: {
            accept: '*/*',
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
        node.data = await response.json();
      }
    }
    return node.data;
  }

  async function runReconciliation(withRefresh) {
    if (!reconciliationData || withRefresh) {
      setErrorMessage(null);
      setLoading(true);
      setResponseData(null);
      setReconciliationFormSelections(defaultReconciliationFormSelections);
      setRenderingDiagnosisDashboard(false)
      let url = '/api/logs/status-reconciliation';

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            accept: '*/*',
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          let errorMessage = response.status === 404 ? "No event sales found" : `Unexpected HTTP Status Code: ${response.status}. Please review the logs for further context.`;
          setErrorMessage(errorMessage);
          setLoading(false);
          return;
        }

        let data = await response.json();

        setResponseData(data);
        setReconciliationData(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    } else {
      if (reconciliationData === responseData) {
        setErrorMessage(
          'If you would like to refresh the reconciliation data, please use the refresh button in the upper right corner'
        );
      } else {
        setResponseData(reconciliationData);
      }
    }
  }

  async function attemptOfferTransitions() {
    setErrorMessage(null);
    setLoading(true);

    if (!reconciliationData) {
      return;
    }

    const inspectionCompleteOfferIds = reconciliationData.offersWaitingForActiveListing.inspectionComplete.map(o => o.offerId);
    const readyForListingOfferIds = reconciliationData.offersWaitingForActiveListing.readyForListing.map(o => o.offerId);
    const pendingListingOfferIds = reconciliationData.offersWaitingForActiveListing.pendingListing.map(o => o.offerId);
    const readyForAuctionOfferIds = reconciliationData.offersWaitingForActiveListing.readyForAuction.map(o => o.offerId);
    const errorOfferIds = reconciliationData.offersWaitingForActiveListing.error.map(o => o.offerId);
    const saleActiveOnlyOfferIds = reconciliationData.offersInUpaasSaleActiveOnly.map(o => o.offerId);

    let offerIdsToTransition = [inspectionCompleteOfferIds, readyForListingOfferIds, pendingListingOfferIds, readyForAuctionOfferIds, errorOfferIds, saleActiveOnlyOfferIds]

    let url = '/api/tools/transition-offers';

    try {
      let request = {
        "offerIds": offerIdsToTransition.flat()
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(request)
      });

      if (!response.ok) {
        setErrorMessage('Must be signed into VAuto');
        setLoading(false);
        return;
      }

      runReconciliation(true);

    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  function buildTree(e) {
    e.preventDefault();
    e.stopPropagation();

    let sanitizedVins = vins.toString().split(/[ ,]+/);

    // Update the location query string with the vins so we can deep link searches.
    setVinsQueryString(sanitizedVins.join(','));
    setVins(sanitizedVins.join(','));

    getData(sanitizedVins).then((data) => {
      const myTree = new Tree('#container', {
        data: data,
        closeDepth: 1,
        onChange: function () {
          console.log('Selected Node->' + this.selectedNode?.id);
          console.log('Previous Node->' + this.previousNode?.id);

          if (
            this.selectedNode.children != null &&
            this.selectedNode.children.length > 0
          )
            return;
          if (this.previousNode?.id != null) {
            this.liElementsById[this.previousNode.id].classList.remove(
              'treejs-selected-node'
            );
            if (this.previousNode == this.selectedNode)
              this.selectedNode.data = null;
          }
          this.liElementsById[this.selectedNode.id].classList.add(
            'treejs-selected-node'
          );

          setLoading(true);
          setErrorMessage(null);
          setResponseData(null);
          setToolVin(null);
          setSellerId(null);
          setNumOfDays(null);
          isSubscribed = true;
          processNode(this.selectedNode).then((data) => {
            setLoading(false);
            if (isSubscribed) {
              isSubscribed = false;
              setResponseData(data);
            }
          });
        },
      });
    });
  }

  function eventReplay(e) {
    e.preventDefault();
    e.stopPropagation();

    async function replayEvent(data) {
      let url = '/api/tools/event-replay';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      });

      return response.json();
    }

    const data = {
      vin: toolVin,
      sellerId: sellerId,
      numOfDays: numOfDays,
    };

    replayEvent(data).then((data) => {
      setLoading(false);
      setResponseData(data);
      isSubscribed = false;
    });
  }

  async function flipback() {
    let url = `/api/tools/flip-back`;
    const data = {
      vin: toolVin
    }
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data)
    });
    setResponseData(await response.json());
  }

  function deactivateInventory(e) {
    e.preventDefault();
    e.stopPropagation();

    async function deactivate(data) {
      let url = `/api/tools/deactivate-inventory`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      });

      return response.json();
    }

    const data = {
      vin: toolVin,
      sellerId: sellerId,
    };

    deactivate(data).then((data) => {
      setLoading(false);
      setResponseData(data);
      isSubscribed = false;
    });
  }

  const SearchVinsForm = () => {
    const checkboxes = [];
    for (const [key, value] of Object.entries(reconciliationFormSelections)) {
      checkboxes.push(
        <Col xs={4} key={key}>
          <Form.Check
            key={key}
            className="checkbox"
            type="checkbox"
            label={key}
            checked={reconciliationFormSelections[key]}
            onChange={() => {
              setReconciliationFormSelections({
                ...reconciliationFormSelections,
                [key]: !value,
              });
            }}
          />
        </Col>
      );
    }
    return (
      <Form className="w-100">
        <Stack direction="horizontal">
          <Row className="w-100">
            {checkboxes}
          </Row>
        </Stack>
      </Form>
    );
  };

  function populateVinSearchField() {
    let vinsList = [];
    const vinsInSaleMappings = [
      { key: 'UpsideDirect', value: 'vinsInUpsideDirectOnly' },
      { key: 'SaleActive', value: 'offersInUpaasSaleActiveOnly' },
    ];

    const vinsOfInterestMappings = [
      { key: 'InspectionComplete', value: 'inspectionComplete' },
      { key: 'ReadyForListing', value: 'readyForListing' },
      { key: 'PendingListing', value: 'pendingListing' },
      { key: 'ReadyForAuction', value: 'readyForAuction' },
      { key: 'ErrorState', value: 'error' },
    ];

    vinsInSaleMappings.forEach(({ key, value }) => {
      if (reconciliationFormSelections[key]) {
        vinsList = [...vinsList, ...(reconciliationData[value] ?? [])];
      }
    });

    if (reconciliationData.offersWaitingForActiveListing) {
      vinsOfInterestMappings.forEach(({ key, value }) => {
        if (reconciliationFormSelections[key]) {
          vinsList = [
            ...vinsList,
            ...(reconciliationData.offersWaitingForActiveListing[value] ?? []),
          ];
        }
      });
    }

    setVins(vinsList.map((e) => e.vin));
  }

  const PopulateVinsButton = () => {
    return (
      <Button
        variant="primary"
        type="button"
        id="populate-vin-search-button"
        className="populateVinSearchButton mr-4"
        aria-label="Populate VIN Search Field Button"
        onClick={() => {
          populateVinSearchField();
        }}> Populate VINs
      </Button>
    );
  };

  const RefreshButton = () => {
    return (
      <Button
        variant="success"
        type="button"
        id="reconciliation-refresh"
        className="refreshButton"
        aria-label="Refresh Reconciliation Data"
        onClick={() => runReconciliation(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-arrow-clockwise"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
          />
          <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
        </svg>
      </Button>
    );
  };

  const TransitionButton = () => {
    return (
      <Button
        variant="primary"
        className="flex-fill"
        type="button"
        onClick={() => {
          attemptOfferTransitions();
        }}> Transition Pending Offers
      </Button>
    );
  };

  return (
    <>
      {errorMessage && (
        <Row className="errorMessageRow" id="error-message-row">
          <h5 className="errorMessage">{errorMessage}</h5>
        </Row>
      )}
      <Container fluid>
        <Row>
          <Col sm={4} className="bg-light vh-100 position-fixed overflow-auto">
            <div className="logo-container">
              <img
                className="upsideImage"
                src={require('./UpsideSupportToolLogo.png')}
                alt="Upside Logo"
              ></img>
              <div className="buildInfo">
                {buildDate} {environment}
              </div>
            </div>
            <Row className="p-0">
              <Container fluid className="py-3">
                <Form
                  className="d-flex"
                  onSubmit={(event) => {
                    buildTree(event);
                    setRenderingDiagnosisDashboard(false);
                  }}
                >
                  <Stack gap={3}>
                    <FormControl
                      id="vin-search-bar"
                      type="search"
                      placeholder="VIN (Comma-delimited values)"
                      className="me-2"
                      aria-label="Search VIN"
                      value={vins}
                      onChange={(e) => setVins(e.target.value)}
                      disabled={!isViewer()}
                    />
                    <Button
                      className="searchButton"
                      type="submit"
                      disabled={!isViewer()}
                    >
                      Search
                    </Button>

                    {isEditor() ? (
                      <Stack direction="horizontal" gap={3}>
                        <Button
                          className="reconciliationButton flex-fill"
                          type="button"
                          onClick={() => {
                            runReconciliation(false);
                          }}
                        >
                          Run Reconciliation
                        </Button>
                      </Stack>
                    ) : null}
                  </Stack>
                </Form>
              </Container>
            </Row>
            {!renderingDiagnosisDashboard && <div id="container"></div>}
            <Row className="separator">
              <DiagnosisRunActionPanel onSubmit={handleRunDiagnosisActionSubmit} isViewer={isViewer} />
            </Row>
            <Row className="separator">
              <GrantExceptionPanel isViewer={isViewer} />
            </Row>
          </Col>
          {!renderingDiagnosisDashboard && <Col xs={{ span: 8, offset: 4 }} className="dataOutput">
            {toolVin != null && toolType == 'EventReplay' && (
              <Row className="p-0">
                <Container fluid className="py-3">
                  <Form
                    className="d-flex"
                    style={{ width: '40rem' }}
                    onSubmit={(event) => {
                      eventReplay(event);
                    }}
                  >
                    <Stack gap={3}>
                      <FormControl
                        id="vin-bar"
                        type="input"
                        placeholder="VIN"
                        className="me-2"
                        aria-label="Enter VIN"
                        value={toolVin}
                        onChange={(e) => setToolVin(e.target.value)}
                      />
                      <FormControl
                        id="account-number-bar"
                        type="input"
                        placeholder="Manheim Account Number (Optional)"
                        className="me-2"
                        aria-label="Manheim Account Number"
                        onChange={(e) => setSellerId(e.target.value)}
                      />
                      <FormControl
                        id="date-bar"
                        type="input"
                        placeholder="Number of Days (Optional)"
                        className="me-2"
                        aria-label="Enter number of days"
                        onChange={(e) => setNumOfDays(e.target.value)}
                      />
                      <Button className="replayButton" type="submit">
                        Replay Event
                      </Button>
                    </Stack>
                  </Form>
                </Container>
              </Row>
            )}
            {toolVin != null && toolType == 'Flipback' && (
              <Row className="p-0">
                <Container fluid className="py-3">
                  <Stack gap={3}>
                    <Stack gap={0}>
                      <h4>Revert Vin {toolVin} back to Unit Owner</h4>
                      <div style={{ fontSize: 'small' }}>
                        * This will withdraw the unit from Upside sale if it is
                        listed. The call will fail if the owner unit already
                        exists.
                      </div>
                    </Stack>

                    <Button
                      className="replayButton"
                      type="submit"
                      onClick={async () => {
                        await flipback();
                      }}
                    >
                      Flip Events Back to Dealer
                    </Button>
                  </Stack>
                </Container>
              </Row>
            )}
            {toolVin != null && toolType == 'DeactivateInventory' && (
              <Row className="p-0">
                <Container fluid className="py-3">
                  <Form
                    className="d-flex"
                    style={{ width: '40rem' }}
                    onSubmit={(event) => {
                      deactivateInventory(event);
                    }}
                  >
                    <Stack gap={3}>
                      <h4>Deactivate Inventory for Vin {toolVin}</h4>
                      <div style={{ fontSize: 'small' }}>
                        * This will deactivate the unit from Manheim.
                      </div>
                      <FormControl
                        id="account-number-bar"
                        type="input"
                        placeholder="Manheim Account Number"
                        className="me-2"
                        aria-label="Manheim Account Number"
                        onChange={(e) => setSellerId(e.target.value)}
                      />
                      <Button className="deactivateButton" type="submit">
                        Deactivate Inventory
                      </Button>
                    </Stack>
                  </Form>
                </Container>
              </Row>
            )}

            {responseData == null && toolVin == null && !loading && (
              <h4 className="dataOutputStarter">
                Enter a comma-delimited list of VINs and select what you would
                like to view </h4>
            )}
            {loading && (
              <Spinner
                animation="border"
                role="status"
                className="dataOutputSpinner"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
            {reconciliationData != null &&
              responseData === reconciliationData &&
              toolVin == null &&
              !loading && (
                <Container fluid  className="mb-4" >
                  <Row className="justify-content-md-center text-center mb-4">
                    <h2>
                      Upside Reconciliation Report
                    </h2>
                  </Row>
                  <Row>
                    <hr />
                  </Row>
                  <Row className="justify-content-md-left mb-4">
                    <Stack direction="horizontal" gap={3}>
                      <SearchVinsForm></SearchVinsForm>
                      <Stack gap={3}>
                        <Stack direction="horizontal" gap={3}>
                          <PopulateVinsButton></PopulateVinsButton>
                          <RefreshButton></RefreshButton>
                        </Stack>
                        <TransitionButton></TransitionButton>
                      </Stack>
                    </Stack>
                  </Row>
                  <Row>
                    <hr />
                  </Row>
                </Container>
              )}
            {responseData != null && !loading && <ReactJson src={responseData} />}
          </Col>}
          {renderingDiagnosisDashboard && dealerDiagnosisResponse && dealer && <Col xs={{ span: 8, offset: 4 }} className="dataOutput">
            <DiagnosisDashboard dealerDiagnosisResponse={dealerDiagnosisResponse} dealer={dealer} />
          </Col>}
        </Row>
      </Container>
    </>
  );
}

export default App;
